@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body{
  background-color: #F5F8F9;
  font-family: 'Roboto', sans-serif;
  transition-duration: 4s;
  transition-delay: 2s;
}

.app_title{
  font-size: 38px;
  font-weight: 500;
  margin-top: 73px;
  margin-bottom: 45px;
  color: #182736;
  text-align: center;
}

.tabs_container{
  display: flex;
  justify-content: center;
}

.app_Tabs{
  max-width: 980px;
  width: 100%;
}

.App .nav-tabs .nav-link.active{
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #182736;
  background: none;
  color: #182736;
}

.App .nav-tabs .nav-link{
  border: none;
  font-size: 18px;
  color: #7E8291;
}

.App .nav{
  column-gap: 20px;
}

.App .tab-content{
  margin-top: 30px;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 54px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.App .input_form{
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.App .input_form input{
  width: 100%;
  padding: 5px 10px;
  border: solid 1px #B8B8B8;
}

.App .input_info{
  color: #7E8291;
  font-size: 14px;
  margin-bottom: 8px;
}

.enable_btn{
  background-color: #75C5E1;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 14px;
  min-width: 120px;
  line-height: 26px;
}

.App .input_red{
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
}

.App .input_green{
  color: green;
  font-size: 14px;
  margin-bottom: 8px;
}

.App .input_title{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1px;
  color: #182736;
}

.input_group{
  margin-bottom: 35px;
}

.btn_conn_wallet{
  color: white;
  background-color: #75C5E1;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  padding: 13px 53px;
}

.btn_conn_wallet:disabled{
  background-color: #B8B8B8;
  cursor: not-allowed;
}

.btnConnect_content{
  text-align: center;
}

.wallet_info{
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.App .top_msg{
  margin-bottom: 40px;
}

.App .amount_title{
  font-size: 14px;
  font-weight: 400;
}

.input_topGroup{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rewards_amount{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.soy_amount_rew{
  font-size: 48px;
  font-weight: 700;
  color: #3E4B78;
}

.soy_txt_rew{
  font-size: 18px;
  font-weight: 500;
  color: #3E4B78;
}

.rewards_info{
  font-size: 18px;
  font-weight: 500;
  color: #3E4B78;
  margin: 30px 0;
}

.enable_group{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

.StatsBottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

.Stat{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StatTop{
  font-size: 24px;
  font-weight: 500;
  color: #3E4B78;
  margin-top: 20px;
}

.StatBottom{
  font-size: 18px;
  font-weight: 400;
  color: #7E8291;
}